<template>
  <div>
    <div class="d-flex">
      <div class="mr-3">
        <!-- <a-input-search size="large" placeholder="input search text" enter-button v-model="search" @search="searchEmployee" /> -->
        <a-input
          v-model="search"
          @keyup="searchEmployee"
          size="large"
          placeholder="Cari pegawai..."
        />
      </div>
      <!-- <div class="mr-3">
        <a-select
          size="large"
          default-value="Semua"
          style="width: 150px;"
        >
          <a-select-option key="Semua">Semua</a-select-option>
          <a-select-option key="KB-TK">KB-TK</a-select-option>
          <a-select-option key="SD">SD</a-select-option>
          <a-select-option key="SMP">SMP</a-select-option>
          <a-select-option key="Karyawan">Karyawan</a-select-option>
        </a-select>
      </div> -->
      <div class="ml-auto">
        <a-button
          @click.prevent="addEmployeeSelected"
          :disabled="!selectedRowEmployee.length"
          class="ml-3"
          type="primary"
          size="large"
          :loading="loadingAddEmployee"
        >
          <a-icon v-if="!loadingAddEmployee" type="plus" /> {{loadingAddEmployee ? 'Menambahkan...' : `Tambah ${selectedRowEmployee.length ? selectedRowEmployee.length : ''} Pegawai Terpilih`}}
        </a-button>
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="employeeColumns"
        :data-source="employeeDataTable"
        :pagination="paginationEmployee"
        :loading="loadingTableEmployee"
        :row-selection="rowSelection"
        @change="handleTableChange"
        bordered
      >
        <div slot="jenis_kelamin" slot-scope="value">
          <span v-if="value"> {{value}}</span>
          <span v-else>-</span>
        </div>
        <div slot="unit" slot-scope="value">
          <span v-if="value"> {{value.nama}}</span>
          <span v-else>-</span>
        </div>
        <div slot="jabatan" slot-scope="value">
          <template v-if="value.length">
            <a-tag
              v-for="item in value"
              :key="item.id"
              color="geekblue"
            >
            {{ item.jabatan.nama.toUpperCase() }}
          </a-tag>
          </template>
          <template v-else>
            <span>-</span>
          </template>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddEmployee',
  props: {
    dataShift: {
      type: Object,
      required: true,
    },
    employeeColumns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loadingAddEmployee: false,
      loadingTableEmployee: false,
      paginationEmployee: {},
      employeeDataTable: [],
      selectedRowEmployee: [],
      search: '',
    }
  },
  methods: {
    closeModalAddEmployee() {
      console.log('emit close')
      this.$emit('close-modal')
    },
    addEmployeeSelected() {
      this.$confirm({
        title: 'Peringatan',
        content: (
          <div class="d-flex flex-column"><p>Apakah anda yakin ini menambahkan {this.selectedRowEmployee.length} pegawai?</p><p class="text-danger mt-4">*Perubahan ini akan mempengaruhi sistem absensi pegawai</p></div>
        ),
        onOk: () => {
          this.loadingAddEmployee = true
          const id = this.dataShift.id
          this.$store.dispatch('shift/POST_SHIFT_EMPLOYEE_SELECTED', { id, dataPegawai: this.selectedRowEmployee })
            .then(isSuccess => {
              this.loadingAddEmployee = false
              this.closeModalAddEmployee()
              if (isSuccess) {
                this.$notification.success({
                  message: 'Berhasil',
                  description: `${this.selectedRowEmployee.length} data pegawai berhasil ditambahkan`,
                })
                this.selectedRowEmployee = []
              } else {
                this.$notification.error({
                  message: 'Gagal',
                  description: 'data pegawai gagal ditambahkan',
                })
                this.selectedRowEmployee = []
              }
            })
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Tambah Sekarang',
        cancelText: 'Batal',
      })
    },
    searchEmployee() {
      this.fetchDataEmployee({
        search: this.search,
      })
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.paginationEmployee }
      let order = 'ASC'
      let sortBy = ''
      pager.current = pagination.current
      this.paginationEmployee = pager
      if (sorter) {
        sortBy = sorter.field
        // console.log(sorter)
        if (sorter.order === 'ascend') {
          order = 'ASC'
        } else if (sorter.order === 'descend') {
          order = 'DESC'
        }
      }
      this.fetchDataEmployee({
        page: pagination.current,
        order,
        sortBy,
      })
    },
    fetchDataEmployee(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      // console.log(params)
      this.loadingTableEmployee = true
      this.$store.dispatch('shift/FETCH_ALL_EMPLOYEE', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          console.log(res)
          this.loadingTableEmployee = false
          const pagination = { ...this.paginationEmployee }
          pagination.total = res.total
          this.paginationEmployee = pagination
          this.employeeDataTable = res.pegawai.map(el => {
            return {
              key: el.id,
              id: el.id,
              nik: el.nik,
              nama: el.nama,
              jenis_kelamin: el.jenis_kelamin,
              shift_pegawai: el.shift_pegawai,
              unit: el.unit,
              jabatan: el.jabatan_pegawais,
            }
          })
        })
    },
  },
  mounted() {
    this.fetchDataEmployee()
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
          this.selectedRowEmployee = selectedRows
        },
        getCheckboxProps: record => {
          console.log(record)
          return {
            props: {
              disabled: Boolean(record.shift_pegawai), // Column configuration not to be checked
            },
          }
        },
      }
    },
  },
}
</script>

<style>

</style>
